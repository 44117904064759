<template>
  <div class="qr-code-generator">
    <canvas ref="qrCanvas"></canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
	props: {
	   itemWidth: {
	       type: Number,
	       default: 240
	   },
	   itemHeight: {
	       type: Number,
	       default: 240
	   }
	},
  data() {
    return {
      url: 'https://nsuphmasftwjmloprmug.com', // 用户输入的 URL
    };
  },
  mounted() {
	 this.orcodeAxios();
     this.generateQRCode();
  },
  methods: {
      async generateQRCode() {
        if (this.url.trim()) {
          try {
            const canvas = this.$refs.qrCanvas;
			const options = {
			  width: this.itemWidth,
			  height: this.itemHeight
			};
            await QRCode.toCanvas(canvas, this.url,options);
          } catch (error) {
            console.error('二维码生成失败:', error);
          }
        } else {
          console.warn('请输入一个网址');
        }
      },
	  orcodeAxios(){
	  	var that = this;
		var params = new URLSearchParams();
		params.append('version', '4');
	  	this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/zhuanfa/orcode',params)
	  	  .then(function (response) {
	  		  var datas = response.data.data;
	  		  if(response.data.code==1){
				 that.url = "https://"+datas.address;
	  			that.generateQRCode();
	  		  }
	  	  })
	  	  .catch(function (error) {
	  		  console.log(error);
	  	  });
	  },
    },
};
</script>

<style scoped>

</style>
